.gallery-section {
	padding: 15px 0 0;
	font-size: 14px;
	line-height: calc(26 / 14);
	
	@include media('≥tablet') {
		font-size: 16px;
		line-height: calc(28 / 16);
	}
	
	@include media('≥desktop') {
		padding: 60px 0 0;
	}

	h1 {
		margin: 0;
	}
}

.gallery-section-columns {
	margin-bottom: 52px;
	
	@include media('≥tablet') {
		display: flex;
		margin-bottom: 72px;
	}

	&__col:first-child {
		margin-bottom: 30px;

		@include media('≥tablet') {
			width: 65%;
			margin-bottom: 0;
		}
	}

	&__col:last-child {

		@include media('≥tablet') {
			width: 35%;
			padding: 20px 5px 22px 43px;
		}
	}
}

.heading-slider {
	h1 {
		font-weight: $semibold;
	}
}

.academies-slider {
	margin-right: -40px;
	
	@include media('≥phone') {
		margin-right: 0;
	}

	.slick-list {
		padding-right: 40px;

		@include media('≥phone') {
			padding-right: 0;
		}
	}
}

.academies-slide-card {
	position: relative;
	padding-top: 130%;
	margin: 0 2px;
	color: $white;
	font-weight: 600;
	font-size: 2vw;
	line-height: 1.8;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	background-color: #f4f4f4;
	background-blend-mode: luminosity;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	
	@include media('≥phone') {
		font-size: 1.5vw;
	}
	
	@include media('≥tablet') {
		font-size: 1vw;
	}
			
	@include media('≥widescreen') {
		font-size: 14px;
	}

	&:hover {
		.academies-slide-card__content {
			opacity: 1;
			visibility: visible;
		}
	}

	.icon {
		font-size: 12px;
		line-height: 30px;

		@include media('≥tablet') {
			font-size: 20px;
		}
	}

	.icon-arrow-right-long {
		padding: 0 10px ;
		font-size: 10px;
	}

	p,
	h4 {
		margin: 0;
	}

	h4 {
		font-weight: 300;
		font-size: 3.4vw;
		line-height: 1.5;
		text-transform: capitalize;

		@include media('≥phone') {
			font-size: 2.5vw;
		}

		@include media('≥tablet') {
			font-size: 1.9vw;
		}
		
		@include media('≥desktop') {
			font-size: 1.6vw;
		}
		
		@include media('≥widescreen') {
			font-size: 24px;
		}
	}

	&__content {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: end;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 15%;
		opacity: 0;
		visibility: hidden;
		transition: all $animation-speed;
		background: linear-gradient(180deg, rgba(209, 209, 209, 0.6) 0%, #353535 79.69%);

		&--interior {
			background: linear-gradient(180deg, rgba(99, 207, 227, 0.6) 0%, #63CFE3 79.69%);
		}
		&--garden{
			background: linear-gradient(180deg, rgba(94, 194, 165, 0.6) 0%, #5EC2A5 79.69%);
		}
		&--fashion {
			background: linear-gradient(180deg, rgba(60, 149, 155, 0.6) 0%, #3C959B 79.69%);
		}
		&--marketing {
			background: linear-gradient(180deg, rgba(180, 189, 0, 0.6) 0%, #B4BD00 79.69%);
		}
		&--photography {
			background: linear-gradient(180deg, rgba(245, 128, 37, 0.6) 0%, #F58025 79.69%);
		}
		&--technology {
			background: linear-gradient(180deg, rgba(89, 126, 255, 0.6) 0%, #597EFF 79.69%);
		}
	}
}