// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
    border: $btn-border-width solid $btn-border;
    border-radius: $btn-border-radius;
    display: inline-block;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    padding: $btn-padding;
    vertical-align: top;
    text-align: center;
    background-color: $btn-background;
    color: $btn-color;
    min-width: 100px;
    text-transform: uppercase;
    font-weight: $semibold;
    letter-spacing: 0.03em;

    &:hover {
        background-color: $btn-hover-background;
        border: $btn-border-width solid $btn-hover-border;
        color: $btn-hover-color;
    }

    &--icon {
        span::before {
            margin-left: 10px;
            color: inherit;
            font-size: 12px;
        }
    }

    &--chartreuse {
        &:hover {
            background-color: $yellow-green;
            border: $btn-border-width solid $yellow-green;
            color: $btn-hover-color;
        }
    }

    &--teal {
        &:hover {
            background-color: $teal;
            border: $btn-border-width solid $teal;
            color: $btn-hover-color;
        }
    }

    &--orange {
        &:hover {
            background-color: $orange;
            border: $btn-border-width solid $orange;
            color: $btn-hover-color;
        }
    }

    &--blue {
        &:hover {
            background-color: $blue;
            border: $btn-border-width solid $blue;
            color: $btn-hover-color;
        }
    }

    .icon-arrow-right-long--bottom {
        display: inline-block;
        &:before {
          content: $icon-arrow-right-long; 
          margin: 0;
        }
        transform: rotate(90deg);
        padding-bottom: 10px;
        padding-right: 10px;
      }
}