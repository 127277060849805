.about-section {
  padding-top: 50px;
  margin-bottom: 20px;
  line-height: calc(24 / 14);

  @include media("≥tablet") {
    padding-top: 140px;
    line-height: calc(28 / 18);
  }

  .btn {
    margin-top: 5px;

    @include media("≥tablet") {
      margin-top: 28px;
    }
  }

  &--mod-1 {
    font-size: 14px;
    line-height: calc(26 / 14);

    @include media("≥tablet") {
      font-size: 18px;
      line-height: calc(28 / 18);
    }

    h2 {
      font-size: 32px;
      line-height: calc(42 / 32);

      @include media("≥tablet") {
        font-size: 40px;
        line-height: calc(53 / 40);
      }
    }

    @include media("≥tablet") {
      padding-top: 127px;
    }
  }

  &--mod-2 {
    font-size: 14px;
    line-height: calc(26 / 14);

    @include media("≥tablet") {
      padding-top: 137px;
      font-size: 18px;
      line-height: calc(28 / 18);
    }

    .about-columns__col:first-child {
      @include media("≥tablet") {
        padding-bottom: 0;
        padding-top: 15px;
        padding-right: 15px;
      }
    }

    .about-columns__col:last-child {
      margin-bottom: 66px;
      @include media("≥tablet") {
        margin-bottom: 0;
        padding: 0 0 0 8%;
      }
    }
  }

  &--mod-3 {
    @include media("≥tablet") {
      margin-bottom: 150px;
    }
  }
}

.about-columns {
  display: flex;
  flex-direction: column;

  @include media("≥tablet") {
    flex-direction: row;
    align-items: center;
  }

  @include media("≥widescreen") {
    align-items: start;
  }

  &__col {
    @include media("≥tablet") {
      width: 50%;
    }
  }

  &__col:first-child {
    margin-bottom: 47px;
    @include media("≥tablet") {
      padding-right: 20px;
      padding-bottom: 50px;
      margin-bottom: 0;
    }
  }

  &__col:last-child {
    @include media("≥widescreen") {
      padding: 10px 0 0 8%;
    }
  }

  &--col-reverse {
    flex-direction: column-reverse;

    @include media("≥tablet") {
      flex-direction: row;
      align-items: center;
    }
  }

  &--row-reverse {
    @include media("≥tablet") {
      flex-direction: row-reverse;
    }

    .about-columns__col:first-child {
      margin-bottom: 0;
      @include media("≥tablet") {
        padding: 0 0 22px 15px;
      }
    }

    .about-columns__col:last-child {
      margin-bottom: 47px;
      @include media("≥widescreen") {
        padding: 10px 8% 0 0;
      }
    }

    .about-visual {
      margin-right: 0;
      margin-left: -40px;
      border-radius: 0 61% 0 0;

      @include media("≥1390px") {
        margin-left: calc((100vw - 1310px) / 2 * -1);
      }
    }
  }
}

.about-visual {
  position: relative;
  padding-top: 90vw;
  margin-right: -40px;
  border-radius: 0 0 0 61%;
  overflow: hidden;

  @include media("≥tablet") {
    padding-top: 50vw;
  }

  @include media("≥widescreen") {
    padding-top: 43vw;
  }

  @include media("≥1390px") {
    margin-right: calc((100vw - 1310px) / 2 * -1);
  }

  &::after {
    position: absolute;
    content: "";
    bottom: -3px;
    right: -3px;
    width: 47%;
    height: 47%;
    border: 1.7px solid $white;
    border-radius: 400px 0 0 0;

    @include media("≥tablet") {
      border: 3px solid $white;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
