.values-section {
  padding: 33px 0;
  @include media("≥tablet") {
    padding: 137px 0 0;
  }
}

.center-heading {
  max-width: 704px;
  margin: 0 auto 62px;
  text-align: center;

  @include media("≥tablet") {
    margin-bottom: 37px;
  }
}

.values-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -12px;

  @include media("<tablet") {
    margin: 0 -14px;
  }

  li {
    width: 100%;
    padding: 0 12px;
    margin-bottom: 25px;
    @include media("≥phone") {
      width: 313px;
    }
    @include media("≥tablet") {
      width: 418px;
      padding: 0 14px;
      margin-bottom: 30px;
    }

    @include media("≥desktop") {
      width: calc(100% / 3);
    }
  }
}

.values-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 313px;
  padding: 10%;
  font-weight: $light-font;
  font-size: 27px;
  line-height: 28px;
  background-color: $gray-200;

  @include media("≥tablet") {
    min-height: 418px;
    font-size: 36px;
    line-height: 38px;
  }

  .values-item__description,
  .values-item__number {
    transition: all $animation-speed;
  }

  &:hover {

    @include media('≥tablet') {
      .values-item__description,
      .values-item__number {
        opacity: 0;
        visibility: hidden;
      }
      .values-item__hov-content {
        opacity: 1;
        visibility: visible;
  
        p {
          margin-bottom: 50px;
          @include media("≥tablet") {
            margin-bottom: 100px;
          }
        }
      }
    }
  }

  &__number {
    font-weight: $thin;
    font-size: 54px;
    line-height: 65px;
    color: $brown;

    @include media("≥tablet") {
      font-size: 72px;
      line-height: 87px;
    }
  }

  &__description {
    margin-bottom: 7px;
  }

  &__hov-content {
    font-size: 13px;
    line-height: 18px;

    @include media('≥tablet') {
      position: absolute;
      content: "";
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: end;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: $white;
      font-size: 18px;
      line-height: 28px;
      padding: 10%;
      opacity: 0;
      visibility: hidden;
      transition: all $animation-speed;
  
      &--one {
        background: #5eb3e4;
      }
      &--two {
        background: #5ec2a5;
      }
      &--three {
        background: #b4bd00;
      }
      &--four {
        background: #ef8d31;
      }
      &--five {
        background: #597eff;
      }
      &--six {
        background: #3c959b;
      }
  
      P {
        display: inline-block;
        margin-bottom: 0;
        transition: all 1s ease-out;
      }
    }
  }
}
