.blockquote-section {
  padding: 50px 0;

  @include media("≥tablet") {
    padding: 160px 0 110px;
  }

  p {
    margin-bottom: 26px;

    @include media("≥tablet") {
      margin-bottom: 30px;
    }
  }

  blockquote {
    position: relative;
    font-weight: $extra-light-font;
    font-size: 24px;
    line-height: calc(32 / 24);
    letter-spacing: -0.035em;
    color: $brown;
    border: none;
    padding: 117px 0 0;

    @include media("≥tablet") {
      font-size: 40px;
      line-height: calc(53 / 40);
      padding: 0 0 0 222px;
    }

    &::before {
      position: absolute;
      content: "";
      top: 20px;
      left: 0;
      width: 76px;
      height: 65px;
      background: url('../images/quotes.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include media("≥tablet") {
        top: 15px;
        width: 145px;
        height: 126px;
      }
    }
  }

  cite {
    font-weight: $normal;
    font-style: normal;
    font-size: 18px;
    line-height: calc(18 / 18);
    letter-spacing: -0.035em;
    color: $darker-brown;
  }
}

.cite-row {
  display: flex;
  align-items: center;
}

.cite-photo {
  position: relative;
  width: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;

  &::after {
    position: absolute;
    content: "";
    bottom: -53.6px;
    right: -58.6px;
    width: 100px;
    height: 100px;
    border: 1.6px solid $white;
    border-radius: 50%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cite-name,
.cite-role {
  display: block;
}

.cite-name {
  text-transform: capitalize;
  margin-bottom: 9px;
}

.cite-role {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  opacity: 0.75;
  line-height: calc(16 / 12);
}
