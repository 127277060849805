.stats-section {
	margin-bottom: 18px;

	&__content {
		padding: 38px 40px 58px;
		text-align: center;
		background-color: $gray-200;
		z-index: 0;
		
		@include media('≥tablet') {
			padding: 62px 0 58px;
		}
	}

	&__heading {
		max-width: 650px;
		margin: 0 auto;
	}

	.btn {
		margin-top: 20px;

		@include media('≥desktop') {
			margin-top: 6px;
		}
	}
}

.four-columns-grid {
	list-style: none;
	text-align: left;
	margin: 0;
	padding: 0 13px;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.035em;
	
	@include media('≥phone') {
		padding: 0 20%;
	}
	
	@include media('≥desktop') {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		padding: 45px 0 0;
		margin-bottom: 68px;
		text-align: left;
	}

	li {
		padding: 46px 20px 0;
		border: 1px solid transparent;
		margin-bottom: 20px;
		
		@include media('≥desktop') {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 32px 20% 0;
			margin-bottom: 0;
		}

		&:not(:last-child) {
			padding-bottom: 50px;
			border: none;
			border-bottom: 1px solid $lighter-brown;
			
			@include media('≥desktop') {
				padding-bottom: 0;
				border-bottom: none;
				border-right: 1px solid $lighter-brown;
			}
		}
	}

	.title {
		font-weight: $thin;
		font-size: 72px;
		line-height: calc(87 / 72);
		letter-spacing: -0.035em;
		color: $brown;
		margin-bottom: 0;
	}
}