.strategic-section {

	.center-heading {
		max-width: 642px;
	}
}

.strategic-list {
	list-style: none;
	margin: 0 auto;
	padding: 0 0 55px;
	
	@include media('≥tablet') {
		display: grid;
		align-items: center;
		grid-template-columns: repeat(2, 1fr);
		max-width: 1130px;
		gap: 25px 25px;
		padding: 46px 0 212px;
	}
}

.strategic-item {
	font-size: 18px;
	line-height: calc(28 / 18);
	margin-bottom: 60px;

	@include media('≥tablet') {
		display: flex;
		align-items: center;
		margin-bottom: 0;
	}

	&__number {
		font-weight: $thin;
		font-size: 72px;
		line-height: 72px;
		color: $brown;
		margin: 0 0 32px;
		
		@include media('≥tablet') {
			min-width: 75px;
			margin: 0 40px 0 0;
		}
	}
}