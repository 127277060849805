$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-capitol: "\e900";
$icon-email: "\e90a";
$icon-lamp: "\e90b";
$icon-people: "\e90c";
$icon-phone: "\e90d";
$icon-tools: "\e90e";
$icon-world: "\e90f";
$icon-camera: "\e902";
$icon-chair: "\e903";
$icon-Clothes-hanger: "\e904";
$icon-code: "\e905";
$icon-desktop: "\e906";
$icon-graduation-cap: "\e907";
$icon-leaf: "\e908";
$icon-arrow-right-long: "\e909";
$icon-linkedin-in: "\e901";

