// Typography

@include title-size('≥');

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: $headings-font-family;
  color: $headings-color;
  font-weight: bold;
  margin: 0 0 10px;
  letter-spacing: -0.035em;

  @include media('≥tablet') {
    margin-bottom: 20px;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: $extra-light-font;
  color: $brown;
}

h1,
.h1 {
  line-height: calc(80 / 66);
}

h2,
.h2 {
  line-height: calc(53 / 40);
  margin-bottom: 24px;

  @include media('≥tablet') {
    margin-bottom: 45px;
  }
}

h3,
.h3 {
  line-height: calc(32 / 24);

  @include media('≥tablet') {
    line-height: calc(42 / 32);
  }
}

h5,
.h5 {
  font-weight: $semibold;
  line-height: calc(28 / 16);
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: $light-brown;
  margin-bottom: 30px;
  
  @include media('≥tablet') {
    line-height: calc(28 / 18);
  }
}

h6,
.h6 {
  font-weight: $semibold;
  line-height: calc(28 / 16);
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: $light-brown;
  margin-bottom: 10px;
  
  @include media('≥tablet') {
    line-height: calc(28 / 16);
  }
}

p {
  margin: 0 0 14px;
}

a {
  color: $base-link-color;
  text-decoration: $text-decoration;
  transition: all $animation-speed;

  &:hover,
  &:focus {
    color: $base-link-hover-color;
    text-decoration: $text-decoration-hover;
  }
}

a[href*="mailto:"] {
  word-wrap: break-word;
}
