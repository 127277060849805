.case-stadies-section {
	padding: 30px 0 75px;
	
	 @include media('≥tablet') {
		padding: 94px 0 154px;
	}

	.tosm {
		margin-top: 30px;
		@include media('≥tablet') {
			display: none;
		}
	}
	.tolg {
		display: none;
		@include media('≥tablet') {
			display: inline-block;
			float: right;
			margin-top: -8px;
		}
	}
}

.case-studies-slider {
	margin-right: -40px;
	
	@include media('≥phone') {
		margin-right: 0;
		margin: 0 -10px;
	}

	@include media('≥tablet') {
		margin: 0 -15px;
		padding-top: 15px;
	}
	
	.slick-list {
		padding-right: 40px;
		
		@include media('≥phone') {
			padding-right: 0;
		}
	}
}

.case-studies-slide-card {
	position: relative;
	padding-top: 130%;
	color: $white;
	font-weight: $medium;
	font-size: 11px;
	line-height: calc(17 / 11);
	letter-spacing: 0.035em;
	text-transform: none;
	background-color: #f4f4f4;
	background-blend-mode: luminosity;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin: 0 20px 0 0;
	transition: all $animation-speed;
	
	@include media('≥phone') {
		margin: 0 10px;
		font-size: 2vw;
	}
	
	@include media('≥tablet') {
		margin: 0 15px;
		font-size: 1.4vw;
	}
			
	@include media('≥widescreen') {
		font-size: 16px;
		line-height: calc(24 / 16);
	}

	&:hover {
		&::before {
			opacity: 0;
		}
		.case-studies-slide-card--wayne {
			opacity: 1;
		}
		.case-studies-slide-card__hover-content {
			max-height: 400px;
			opacity: 1;
			visibility: visible;
		}
		.pseudo-btn {
			opacity: 1;
			transform: translateY(0);
		}
	}

	h4 {
		font-weight: $light-font;
		font-size: 25px;
		line-height: calc(26 / 25);
		text-transform: none;

		@include media('≥phone') {
			font-size: 3.7vw;
		}

		@include media('≥tablet') {
			font-size: 2.5vw;
			margin-bottom: 10px;
		}
		
		@include media('≥1400px') {
			font-size: 36px;
			line-height: calc(38 / 36);
			margin-bottom: 20px;
		}
	}

	p {
		margin: 0 0 10px;
		@include media('≥tablet') {
			margin: 0 0 15px;
		}
	}

	.cs-icon {
		display: inline-block;
		margin-bottom: 10px; 

		@include media('≥tablet') {
			margin-bottom: 15px;
		}
		
		&::before {
			font-size: 12px;

			@include media('≥tablet') {
				font-size: 18px;
			}
		}
	}

	.icon-arrow-right-long {
		margin-left: 15px;
		&::before {
			color: inherit;
			font-size: 11px;
		}
	}

	&__content {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: end;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(177, 155, 138, 0.6) 0%, #B19B8A 79.69%);
		padding: 20px 10% 10%;
		
		@include media('≥widescreen') {
			padding: 20px 15% 54px;
		}
		
		&--blue {
			&:hover {
				background: linear-gradient(180deg, rgba(94, 179, 228, 0.6) 0%, #5EB3E4 79.69%);
			}
		}

		&--orange {
			&:hover {
				background: linear-gradient(180deg, rgba(245, 128, 37, 0.6) 0%, #F58025 79.69%);
			}
		}

		&--green {
			&:hover {
				background: linear-gradient(180deg, rgba(180, 189, 0, 0.6) 0%, #B4BD00 79.69%);
			}
		}
	}

	&__hover-content {
		position: relative;
		width: 100%;
		max-height: 0;
		overflow: hidden;
		opacity: 0;
		transition: all calc($animation-speed * 2);
		
		font-weight: $medium;
		font-size: 2.5vw;
		line-height: 16px;
		visibility: hidden;

		@include media('≥phone') {
			font-size: 1.5vw;
			line-height: 11px;
		}
		
		@include media('≥tablet') {
			font-size: 1vw;
			line-height: 13px;
		}
				
		@include media('≥widescreen') {
			font-size: 14px;
			line-height: calc(18 / 14);
		}
	}
}

.pseudo-btn {
	opacity: 0;
	transform: translateY(100%);
	transition: all calc($animation-speed * 2) .3s;
}

.btn-wrap {


	@include media('≥tablet') {
		text-align: center;
	}
}

.case-studies-list {
	list-style: none;
	padding: 0;
	margin: 0 -10px 30px;
	
	@include media('≥phone') {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
	}
	
	@include media('≥desktop') {
		grid-template-columns: repeat(3, 1fr);
		margin: 0 -15px 35px;
	}

	li {
		padding: 10px;

		@include media('≥tablet') {
			padding: 15px;
		}
	}
}

.case-studies-item {
	display: block;
	position: relative;
	padding-top: 130%;
	color: $white;
	font-weight: $medium;
	font-size: 11px;
	line-height: calc(17 / 11);
	letter-spacing: 0.035em;
	text-transform: none;
	background-color: #f4f4f4;
	background-blend-mode: luminosity;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	transition: all $animation-speed;
	
	@include media('≥phone') {
		font-size: 2vw;
	}
	
	@include media('≥tablet') {
		padding-top: 140%;
		font-size: 1.4vw;
	}
			
	@include media('≥widescreen') {
		font-size: 16px;
		line-height: calc(24 / 16);
	}

	&:hover {
		color: $white;
		&::before {
			opacity: 0;
		}
		.case-studies-slide-card--wayne {
			opacity: 1;
		}
		.case-studies-item__hover-content {
			max-height: 400px;
			opacity: 1;
			visibility: visible;
		}
		.pseudo-btn {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&:focus {
		color: $white;
	}
	
	.icon-arrow-right-long {
		margin-left: 15px;
		&::before {
			color: inherit;
			font-size: 11px;
		}
	}

	h4 {
		font-weight: $light-font;
		font-size: 25px;
		line-height: calc(26 / 25);
		text-transform: none;

		@include media('≥phone') {
			font-size: 3.7vw;
		}

		@include media('≥tablet') {
			font-size: 2.5vw;
			margin-bottom: 10px;
		}
		
		@include media('≥1400px') {
			font-size: 36px;
			line-height: calc(38 / 36);
			margin-bottom: 20px;
		}
	}

	p {
		margin: 0 0 10px;
		@include media('≥tablet') {
			margin: 0 0 15px;
		}
	}

	.cs-icon {
		display: inline-block;
		margin-bottom: 10px; 

		@include media('≥tablet') {
			margin-bottom: 15px;
		}
		
		&::before {
			font-size: 12px;

			@include media('≥tablet') {
				font-size: 18px;
			}
		}
	}

	&__content {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: end;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(177, 155, 138, 0.6) 0%, #B19B8A 79.69%);
		padding: 20px 10% 10%;
		
		@include media('≥widescreen') {
			padding: 20px 15% 54px;
		}
		
		&--blue {
			&:hover {
				background: linear-gradient(180deg, rgba(94, 179, 228, 0.6) 0%, #5EB3E4 79.69%);
			}
		}

		&--orange {
			&:hover {
				background: linear-gradient(180deg, rgba(245, 128, 37, 0.6) 0%, #F58025 79.69%);
			}
		}

		&--green {
			&:hover {
				background: linear-gradient(180deg, rgba(180, 189, 0, 0.6) 0%, #B4BD00 79.69%);
			}
		}

		&--darl-teal {
			&:hover {
				background: linear-gradient(180deg, rgba(60, 149, 155, 0.6) 0%, #3C959B 79.69%);
			}
		}

		&--bright-blue {
			&:hover {
				background: linear-gradient(180deg, rgba(89, 126, 255, 0.6) 0%, #597EFF 79.69%);
			}
		}
	}

	&__hover-content {
		position: relative;
		width: 100%;
		max-height: 0;
		overflow: hidden;
		opacity: 0;
		transition: all calc($animation-speed * 2);
		
		font-weight: $medium;
		font-size: 2.5vw;
		line-height: 16px;
		visibility: hidden;

		@include media('≥phone') {
			font-size: 1.5vw;
			line-height: 11px;
		}
		
		@include media('≥tablet') {
			font-size: 1vw;
			line-height: 13px;
		}
				
		@include media('≥widescreen') {
			font-size: 14px;
			line-height: calc(18 / 14);
		}
	}
}