@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?z7w7zd');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?z7w7zd#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?z7w7zd') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?z7w7zd') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?z7w7zd##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-capitol {
  &:before {
    content: $icon-capitol;     
    color: #fff;
  }
}
.icon-email {
  &:before {
    content: $icon-email;     
    color: #989591;
  }
}
.icon-lamp {
  &:before {
    content: $icon-lamp;     
    color: #fff;
  }
}
.icon-people {
  &:before {
    content: $icon-people;     
    color: #fff;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;     
    color: #989591;
  }
}
.icon-tools {
  &:before {
    content: $icon-tools;     
    color: #fff;
  }
}
.icon-world {
  &:before {
    content: $icon-world;     
    color: #989591;
  }
}
.icon-camera {
  &:before {
    content: $icon-camera;     
    color: #fff;
  }
}
.icon-chair {
  &:before {
    content: $icon-chair;     
    color: #fff;
  }
}
.icon-Clothes-hanger {
  &:before {
    content: $icon-Clothes-hanger;     
    color: #fff;
  }
}
.icon-code {
  &:before {
    content: $icon-code;     
    color: #fff;
  }
}
.icon-desktop {
  &:before {
    content: $icon-desktop;     
    color: #fff;
  }
}
.icon-graduation-cap {
  &:before {
    content: $icon-graduation-cap;     
    color: #fff;
  }
}
.icon-leaf {
  &:before {
    content: $icon-leaf;     
    color: #fff;
  }
}
.icon-arrow-right-long {
  &:before {
    content: $icon-arrow-right-long;     
    color: #b19b8a;
  }
}
.icon-arrow-right-long--bottom {
  display: inline-block;
  &:before {
    content: $icon-arrow-right-long;
  }
  transform: rotate(90deg);
}
.icon-linkedin-in {
  &:before {
    content: $icon-linkedin-in;     
    color: #989591;
  }
}

