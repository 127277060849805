// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site
// -----------------------------------------------------------------------------

:root {
  --app-height: 100%;
}

[id="header"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: $white;
  padding: 28px 0 15px;
  z-index: 999;
  transition: all $animation-speed;

  @include media("≥tablet") {
    position: relative;
    padding: 52px 0;
  }
}

.fixed-position[id="header"] {
  box-shadow: 0 0 20px 0 rgba($black, 0.2);
}

.header-holder {
  display: flex;
  align-items: center;
}

.nav-active {
  overflow: hidden;
  overscroll-behavior: none;
  touch-action: none;
  -webkit-overflow-scrolling: none;
}

.logo {
  position: relative;
  width: 60px;
  min-width: 60px;
  margin-right: 80px;

  @include media("≥tablet") {
    width: 100px;
  }

  @include media("≥desktop") {
    width: 114px;
    min-width: 114px;
    margin-right: 100px;
  }

  &:hover {
    .logo__text {
      opacity: 1;
      visibility: visible;
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    width: 50px;
    transform: translateY(-50%);
    margin-left: 15px;
    color: $gray-700;
    opacity: 0;
    visibility: hidden;
    transition: all $animation-speed;

    @include media("≥tablet") {
      width: 75px;
    }

    @include media("≥desktop") {
      width: 97px;
      margin-left: 20px;
    }
  }
}

[id="nav"] {
  @extend %clearfix;

  flex-grow: 1;
}

.nav-drop {
  @include animate(all);

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: calc(100vh - 103px);
  height: calc(var(--app-height) - 103px);
  z-index: 999;
  padding: 50px 40px;
  color: $light-brown;
  font-weight: $normal;
  background: $white;
  font-size: 18px;
  line-height: calc(19 / 16);
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
  overflow: auto;

  @include media("≥tablet") {
    position: static;
    height: auto;
    font-size: 13px;

    padding: 0;
    background: none;
    opacity: 1;
    visibility: visible;
    overflow: visible;
  }

  @include media("≥desktop") {
    font-size: 16px;
  }

  .nav-active & {
    opacity: 1;
    visibility: visible;
  }
}

.primary-menu {
  @extend %listreset;

  @include media("≥tablet") {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 -8px;
  }

  @include media("≥desktop") {
    margin: 0 -17px;
  }

  @include media("≥widescreen") {
    margin: 0 -25px;
  }

  li {
    position: relative;
    list-style: none;
    padding: 20px 0;

    @include media("≥tablet") {
      padding: 0 8px;
    }

    @include media("≥desktop") {
      padding: 0 17px;
    }

    @include media("≥widescreen") {
      padding: 0 25px;
    }

    &.active {
      a {
        color: inherit;

        &::after {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:hover {
      a {
        color: $gray-950;

        &::after {
          opacity: 1;
          visibility: visible;
          background-color: $gray-450;
        }
      }
    }
  }

  a {
    color: inherit;
    position: relative;
    padding-bottom: 10px;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $light-brown;
      opacity: 0;
      visibility: hidden;
      transition: all $animation-speed;
    }
  }
}

.soc-menu {
  @extend %listreset;
  
  display: flex;
  margin: 0 -10px;
  padding-top: 30px;

  @include media("≥tablet") {
    display: none;
  }

  li {
    padding: 5px 10px;
    
    &:hover {
      span::before {
        color: $gray-950;
      }
    }
  }
  
  a {
    span::before {
      transition: all $animation-speed;
      color: $darker-brown;
    }
  }
}

.nav-opener {
  position: relative;
  width: 30px;
  height: 30px;
  font-size: 0;
  line-height: 0;
  float: right;

  @include media("≥tablet") {
    display: none;
  }

  &:before,
  &:after,
  span {
    @include animate(transform opacity);
    background: $light-brown;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    height: 1px;
    transform: translateY(-50%);
  }

  &:before,
  &:after {
    content: "";
  }

  &:before {
    transform: translateY(-9px);
  }

  &:after {
    transform: translateY(6px);
  }

  &:hover {
    &:before,
    &:after,
    span {
      background-color: gray;
    }
  }

  .nav-active & {
    span {
      opacity: 0;
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(45deg) translateY(0);
    }

    &:after {
      transform: rotate(-45deg) translateY(0);
    }
  }
}
