// .video-section {
//     width: 50%;
//     float: left;
//     padding: 0 10px 20px;
// }

/* main styles */
// [data-video].bg-video {
    // position: relative;
    // height: 380px;
    // overflow: hidden;
// }

// [data-video] iframe,
// [data-video] video {
//     display: block;
// }

// .fluid-video {
//     position: relative;
//     padding-bottom: 56.25%; /* 16:9 */
//     height: 0;
// }

// .fluid-video iframe,
// .fluid-video video {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }

// /* popup styles */
// .js-video-popup {
//     position: fixed !important;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     opacity: 0;
//     z-index: 9999;
//     overflow: hidden;
//     visibility: hidden;
//     background: #000;
//     transition: opacity 0.5s linear, visibility 0.5s linear;
// }

// .js-video-popup.active-popup {
//     opacity: 1;
//     visibility: visible;
// }

// .js-video-popup .close {
//     position: absolute;
//     top: 20px;
//     right: 20px;
//     width: 30px;
//     height: 30px;
//     text-indent: -9999px;
//     overflow: hidden;
//     z-index: 2;
//     transition: opacity 0.3s linear;
// }

// .js-video-popup .close:after,
// .js-video-popup .close:before {
//     content: " ";
//     position: absolute;
//     top: 50%;
//     left: 53%;
//     height: 2px;
//     width: 30px;
//     background: #fff;
//     transform: translate(-50%, -50%) rotate(45deg);
// }

// .js-video-popup .close:before {
//     transform: translate(-50%, -50%) rotate(-45deg);
// }

// .js-video-popup iframe,
// .js-video-popup video {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }

// /* decor styles */
// [data-video] .btn-play em {
//     font-style: normal;
//     display: none;
// }

// [data-video].playing .btn-play span,
// [data-video].playing.paused .btn-play em {
//     display: none;
// }

// [data-video].playing .btn-play em,
// [data-video].playing.paused .btn-play span {
//     display: inline;
// }

// [data-video] .btn-play,
// [data-video] .btn-pause {
//     position: absolute;
//     top: 10px;
//     left: 10px;
//     z-index: 2;
//     width: 50px;
//     height: 50px;
//     text-align: center;
//     text-decoration: none;
//     padding: 14px 5px;
//     background: #0900ed;
//     color: #fff;
// }

// [data-video] .btn-pause {
//     left: 70px;
// }
