.video-section {
	padding-top: 11px;
	.btn-play {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: all $animation-speed;
		
		svg {
			circle {
				transition: all $animation-speed;
				fill: transparent;
			}
			path {
				fill:aliceblue;
				transition: all $animation-speed;
			}
		}
	}

	.playing .btn-play {
		display: none;
	}

	a {
		&:hover {
            svg {
                circle {
                    fill: $white;
                }

                path {
                    fill: #597EFF;;
                }
            }
        }

        path {
            transition: 0.3s;
        }
	}

	.video-wrap {
		position: relative;
		padding-top: 57vw;
		// margin-bottom: 32px;
	
		@include media('≥desktop') {
			margin-bottom: 0;
		}
	
		[data-video].bg-video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}