// -----------------------------------------------------------------------------
// This file contains all styles related to the common layout of the site
// -----------------------------------------------------------------------------
[id="wrapper"] {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  width: 100%;
  overflow: hidden;
  background: $base-background-color;
}

main {
  flex-grow: 1;
  padding-top: 103px;

  @include media("≥tablet") {
    padding-top: 0;
  }
}

.container {
  @extend %clearfix;
  max-width: $max-width-center-container;
  padding: $padding-container;
  margin: 0 auto;
}

.decor-left {
  position: relative;

  @include media("≥tablet") {
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 277px;
      height: 275px;
      border: 3px solid $white;
      border-radius: 0 0 100% 0;
      border-left: 0;
      border-top: 0;
      z-index: -1;
    }
  }
}

.decor-right {
  position: relative;

  @include media("≥tablet") {
    &::after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 277px;
      height: 275px;
      border: 3px solid $white;
      border-radius: 0 0 0 100%;
      border-left: 0;
      border-top: 0;
      z-index: -1;
    }
  }
}

.decor-2 {
  position: relative;
  z-index: 0;

  &::after {
    position: absolute;
    content: "";
    bottom: -31px;
    right: -4px;
    width: 50vw;
    height: 50vw;
    border: 1px solid #eee5e5;
    border-radius: 400px 0 0 0;
    z-index: -1;

    @include media("≥phone") {
      bottom: -102px;
      right: -20px;
      width: 25vw;
      height: 25vw;
    }
  }
}

.decor-3 {
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 51vw;
    height: 112vw;
    z-index: -1;
    background-image: url("../images/decor-group.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include media("≥phone") {
      top: 45px;
      width: 23vw;
      height: 50vw;
    }
  }
}

.heading-xl {
	h1 {
		line-height: calc(44 / 36);

		@include media('≥tablet') {
			font-weight: $thin;
			font-size: 96px;
			line-height: calc(96 / 96);
		}
	}
}
