.pressrelease-section {
	padding: 56px 0;
	
	@include media('≥tablet') {
		padding: 74px 0 0;
	}

	h6 {
		font-size: 12px;
		line-height: 16px;
		margin-bottom: 0;

		@include media('≥tablet') {
			font-size: 18px;
			line-height: 16px;
			letter-spacing: 0.15em;
		}
	}

	.heading-xl {
		margin-bottom: 40px;

		@include media('≥tablet') {
			margin-bottom: 20px;
		}
	}
}
.pressrelease-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.pressrelease-item {
	position: relative;
	display: block;
	padding: 35px 0 55px;
	font-size: 12px;
	line-height: calc(16 / 12);
	font-weight: $extra-light-font;
	color: $gray-700;
	letter-spacing: normal;
	border-top: 1px solid $extra-light-brown;
	
	@include media('≥desktop') {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 35px 0 34px;
		font-size: 18px;
		line-height: calc(16 / 18);
	}

	&::before {
		position: absolute;
		content: '';
		top: 50%;
		transform: translateY(-50%) rotate(-45deg);
		left: 5px;
		border: solid $yellow-green;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 5px;
		opacity: 0;
		visibility: hidden;
		transition: all $animation-speed;
	}

	&:hover {
		background-color: $extra-light-brown;
		padding-left: 30px;

		&::before {
			opacity: 1;
			visibility: visible;
		}
		
		@include media('≥desktop') {
			padding-left: 0;
	
			&::before {
				opacity: 0;
				visibility: hidden;
			}
			.pressrelease-title {
				padding-left: 30px;
	
				&::before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	h3 {
		margin-bottom: 0;
	}

	p {
		margin-bottom: 5px;

		@include media('≥desktop') {
			margin-bottom: 0;
			margin-right: 10px;
		}
	}
}

.pressrelease-title {
	position: relative;
	padding-left: 0;
	transition: all $animation-speed;
	
	@include media('≥desktop') {
		width: 51%;
		padding-right: 15px;
	}

	&::before {
		position: absolute;
		content: '';
		top: 50%;
		transform: translateY(-50%) rotate(-45deg);
		left: 5px;
		border: solid $yellow-green;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 5px;
		opacity: 0;
		visibility: hidden;
		transition: all $animation-speed;
	}
}

.title-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 30px 0 32px;

	@include media('≥tablet') {
		padding: 37px 0;
	}

	h6 {
		margin-bottom: 0;
	}
}

.pressrelease-date {
	display: none;
	@include media('≥tablet') {
		display: inline-block;
	}
}