.brands-section {
	padding: 56px 0;

	@include media("≥tablet") {
		padding: 74px 0 140px;
	}

	h1 {
		margin-bottom: 47px;

		@include media("≥tablet") {
			margin-bottom: 65px;
		}
	}
}

.brands-row {
	position: relative;
	display: flex;
	flex-direction: column-reverse;

	@include media("≥tablet") {
		flex-direction: row;
	}
}

.brands-col:first-child {
	@include media("≥tablet") {
		width: 68%;
	}
}
.brands-col:last-child {
	padding: 0 0 55px 0;

	@include media("≥tablet") {
		width: 32%;
		padding: 0 15px 20px 5%;
	}

	@include media("≥1300px") {
		padding: 0 15px 20px 8.8%;
	}
}

.brand-item {
	padding-right: 30px;

	@include media("≥desktop") {
		padding-right: 0;
	}
	&__visual {
		padding: 30px 40px;
		height: 146px;
		max-height: 146px;
		display: flex;
		justify-content: center;
		background: $light-brown;
		transition: all $animation-speed;

		@include media("≥phone") {
			justify-content: start;
		}

		@include media("≥tablet") {
			height: 160px;
			max-height: 160px;
		}

		@include media("≥desktop") {
			justify-content: center;
			padding: 40px;
			height: 196px;
			max-height: 196px;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
			object-position: 50% 50%;
		}

		&--blue {
			&:hover {
				background: #5eb3e4;
			}
		}

		&--green {
			&:hover {
				background: #5ec2a5;
			}
		}

		&--orange {
			&:hover {
				background: #f58025;
			}
		}

		&--teal {
			&:hover {
				background: #3c959b;
			}
		}

		&--chartreuse {
			&:hover {
				background: #b4bd00;
			}
		}
	}

	&__content {
		padding: 32px 10%;
		background: $gray-200;

		@include media("≥tablet") {
			padding: 40px 10%;
		}
	}

	&__title {
		h3 {
			font-size: 26px;
			line-height: calc(48 / 36);

			@include media("≥desktop") {
				font-size: 3vw;
			}

			@include media("≥widescreen") {
				font-size: 36px;
			}
		}
	}

	&__contacts-list {
		list-style: none;
		padding: 0;
		margin: 0 -5px;
		font-size: 12px;

		@include media("≥tablet") {
			font-size: 16px;
			line-height: calc(26 / 16);
		}

		li {
			display: inline-block;
		}
	}

	&__phone {
		margin-right: 20px;
		@include media("≥tablet") {
			margin-right: 27px;
		}
	}

	a {
		color: $darker-brown;

		&:hover {
			color: inherit;
			.brand-item-icon {
				&::before {
					color: $dark-blue;
				}
			}
		}
	}
}

.brand-items {
	@include media("≥tablet") {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 53px 30px;

		@include media("≥desktop") {
			grid-template-columns: repeat(2, 1fr);
			gap: 53px 30px;
		}

		@include media("≥widescreen") {
			gap: 53px 60px;
		}
	}
}

.brand-item-icon {
	padding: 5px;

	&::before {
		transition: all $animation-speed;
	}
}

.brands-address {
	padding: 10px 20px;
	background-color: $gray-200;
	margin-bottom: 35px;
	font-size: 18px;
	line-height: calc(28 / 18);

	@include media("≥tablet") {
		margin-bottom: 63px;
	}

	a {
		display: block;
	}

	h5 {
		font-size: 18px;
		line-height: calc(28 / 18);
		margin-bottom: 0;
	}
}

.tabset {
	list-style: none;
	padding: 0;
	margin: 0;
}

.tabs-list {
	a {
		letter-spacing: -0.035em;
	}
}

.brands-button {
	display: inline-block;
	position: relative;
	padding-left: 0;
	color: $light-brown;
	font-weight: $extra-light-font;
	font-size: 20px;
	line-height: calc(27 / 20);
	margin-bottom: 10px;

	@include media("≥tablet") {
		font-size: 25px;
		line-height: calc(36 / 32);
	}

	@include media("≥widescreen") {
		line-height: calc(42 / 32);
		font-size: 32px;
		margin-bottom: 14px;
	}

	.icon-arrow-right-long {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		opacity: 0;
		visibility: hidden;
		transition: all $animation-speed;
		font-size: 12px;

		@include media("≥tablet") {
			font-size: 16px;
		}

		&::before {
			color: $yellow-green;
		}
	}

	&:hover {
		padding-left: 30px;
		@include media("≥tablet") {
			padding-left: 40px;
		}
		.icon-arrow-right-long {
			opacity: 1;
			visibility: visible;
		}
	}

	&.active {
		padding-left: 30px;
		@include media("≥tablet") {
			padding-left: 40px;
		}
		color: $black;
		.icon-arrow-right-long {
			opacity: 1;
			visibility: visible;
		}
	}
}

.brands-slider {
	margin-right: -40px;

	@include media("≥tablet") {
		margin-right: 0;
	}

	.slick-list {
		padding-right: 40px;

		@include media("≥tablet") {
			padding-right: 0;
		}
	}
}
