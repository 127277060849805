.accreditation-section {
	padding: 67px 0 20px;
	background-color: rgba($gray-500, 0.05);

	@include media('≥1450px') {
		margin-top: -66px;
	}
	
	&--mod-1 {
		background-color: $white;
	
		@include media('≥1450px') {
			margin-top: 0;
		}
	}

	.heading-xl {
		margin-bottom: 55px;
		@include media('≥tablet') {
			margin-bottom: 65px;
		}
	}
}

.accreditations-list {
	list-style: none;
	padding: 0;
	margin: 0 -30px;
	
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	
	@include media('≥tablet') {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		gap: 7px 90px;
		padding-top: 35px;
	}

	li {
		padding: 0 30px;
		margin-bottom: 30px;

		@include media('≥tablet') {
			margin-bottom: 40px;
		}
	}
}

.accr-item {
	display: block;
	width: 123px;
	height: 53px;
	filter: grayscale(1) opacity(.5);
	transition: all $animation-speed;
	
	@include media('≥tablet') {
		display: flex;
		align-items: center;
		width: 160px;
		height: 76px;
	}
	
	&:hover {
		filter: none;
	}
	
	img {
		display: inline;
		vertical-align: top;
		max-width: 100%;
    	max-height: 100%;
		object-fit: cover;
	}
}