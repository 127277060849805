// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/
// Color system

$white: #fff !default;
$gray: #ddd !default;
$gray-100: #f8f9fa !default;
$gray-150: #f5f5f5; //
$gray-200: #fafafa; //
$gray-300: #dee2e6 !default;
$gray-400: #D9D9D9; //
$gray-450: #d4d8dc; //
$gray-500: #9a9a9b; //
$gray-600: #6c757d !default;
$gray-700: #4e4e50; //
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$gray-950: #1c1c1d; //
$black: #000 !default;

$grays: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(("100": $gray-100,
      "200": $gray-200,
      "300": $gray-300,
      "400": $gray-400,
      "500": $gray-500,
      "600": $gray-600,
      "700": $gray-700,
      "800": $gray-800,
      "900": $gray-900 ),
    $grays
);

$blue: #63CFE3; //
$dark-blue: #597EFF; //
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #ef8d31; //
$extra-light-brown: #eee5e5; //
$lighter-brown: #d1c3b5; //
$light-brown: #b19b8a; //
$darker-brown: #989591; //
$brown: #4E4E50; //
$yellow: #ffc107 !default;
$yellow-green: #b4bd00; //
$green: #28a745 !default;
$teal: #5ec2a5; //
$cyan: #17a2b8 !default;

$colors: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(("blue": $blue,
      "indigo": $indigo,
      "purple": $purple,
      "pink": $pink,
      "red": $red,
      "orange": $orange,
      "yellow": $yellow,
      "green": $green,
      "teal": $teal,
      "cyan": $cyan,
      "white": $white,
      "gray": $gray-600,
      "gray-dark": $gray-800 ),
    $colors
);

$primary: $blue  !default;
$secondary: $gray-600  !default;
$success: $green  !default;
$info: $cyan  !default;
$warning: $yellow  !default;
$danger: $red  !default;
$light: $gray-100  !default;
$dark: $gray-800  !default;

$theme-colors: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(("primary": $primary,
      "secondary": $secondary,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
      "light": $light,
      "dark": $dark ),
    $theme-colors
);

// fonts name
$base-font-sans-serif: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

$base-font: 'Inter', $base-font-sans-serif;

// Body
$base-text-color: $darker-brown; //
$base-background-color: $white  !default;
$base-background-body: $white  !default;
$base-background-footer: $gray-200; //
$font-size-base: 14px !default;
$line-height-base: calc(26 / 14);
$font-size-base-lagre: 18px !default;
$line-height-base-lagre: calc(28 / 18);
$base-font-family: $base-font  !default;
$base-min-width: 320px;

// base variables
$base-radius: 0;
$base-radius-small: 0;

// container settings
$max-width-center-container: 1390px;
$gutter: 40px;
$padding-container: 0 $gutter;

// grid sistem
$grid: (
  ph: 'phone',
  sm: 'tablet',
  md: 'desktop'
);

// font weight
$thin: 100;
$extra-light-font: 200;
$light-font: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extra: 800;
$black-font: 900;

// default margins
$vertical-rhythm: $font-size-base + 4;
$widget-margin: 20px;

// Liks
$base-link-color: $darker-brown; //
$base-link-hover-color: $black;
$text-decoration: none;
$text-decoration-hover: none;

// Headers size
$title-palette: (
  mobile: ('h1': 36, //
    'h2': 24, //
    'h3': 24, //
    'h4': 18, //
    'h5': 16, //
    'h6': 14), //
  tablet: ('h1': 55, //
    'h2': 30, //
    'h3': 26, //
    'h4': 20, //
    'h5': 18, //
    'h6': 16), //
  desktop: ('h1': 66, //
    'h2': 40, //
    'h3': 30, //
    'h4': 20, //
    'h5': 18, //
    'h6': 16), //
  widescreen: ('h1': 66, //
    'h2': 40, //
    'h3': 32, //
    'h4': 20, //
    'h5': 18, //
    'h6': 16) //
);

// headings style
$headings-font-family: $base-font-family;
$headings-color: inherit !default;

// Buttons
// btn default
$btn-border-width: 2px; //
// $btn-border:                        $btn-border-width solid transparent !default;
$btn-border-radius: 100px;
$btn-border-radius-sm: $base-radius-small;
$btn-padding: 5px 35px; //
$btn-padding-sm: 4px 10px !default;
$btn-font-size: 14px; //
$btn-line-height: calc(30 / 14); //

$btn-background: $white; //
$btn-border: $extra-light-brown; //
$btn-color: $light-brown; //
// hover
$btn-hover-background: darken($btn-background, 10%) !default;
$btn-hover-border: $btn-hover-background  !default;
$btn-hover-color: $white  !default;

// btn primary
$btn-background-primary: $primary  !default;
$btn-border-primary: $primary  !default;
$btn-color-primary: $white  !default;
// hover
$btn-hover-background-primary: darken($primary, 10%) !default;
$btn-hover-border-primary: $btn-hover-background-primary  !default;
$btn-hover-color-primary: $white  !default;

// btn warning
$btn-background-warning: $warning  !default;
$btn-border-warning: $btn-background-warning  !default;
$btn-color-warning: $white  !default;
// hover
$btn-hover-background-warning: darken($warning, 10%) !default;
$btn-hover-border-warning: $btn-hover-background-warning  !default;
$btn-hover-color-warning: $white  !default;

// Forms
$input-font-size: $font-size-base; // font size for forms elements
$input-line-height: $input-font-size + 2; // line height for forms elements
$input-font-family: $base-font;
$input-padding-vertical: 10px !default; // padding for forms elements
$input-padding-horizontal: 20px !default; // padding for forms elements
$input-padding: $input-padding-vertical $input-padding-horizontal  !default;
$input-border-width: 1px !default; // border input's width
$input-border: $input-border-width solid $black  !default; // border for forms elements
$input-border-radius: 0 !default; // border radius for forms elements
$inpH: $input-border-width + 2 * $input-padding-vertical + $input-line-height;
$input-height: (
  2 * ($input-border-width + $input-padding-vertical) + $input-line-height) !default; // height for forms elements
//submit
$submit-background: $btn-background;
$submit-color: $btn-color;
$submit-height: $input-height;
$submit-border: $btn-border;
$submit-padding: $input-padding;
$submit-font-size: $input-font-size;
$submit-line-height: $input-line-height;
// submit hover
$submit-hover-background: $btn-hover-background; // submit background hover
$submit-hover-color: $btn-hover-color; // submit color hover
//focus
$input-focus-border-color: $black  !default; // border color for forms elements
//placeholder
$placeholder-color: $gray  !default;
// select
$select-height: $input-height;
$select-border: $input-border;
$select-border-radius: $input-border-radius;
$select-padding: $input-padding;
$select-font-size: $input-font-size;
$select-line-height: $input-line-height;

// animation speed
$animation-speed: 0.5s;