.leadership-section {
  padding: 54px 0 61px;

  @include media("≥tablet") {
    padding: 178px 0;
  }

  h2 {
    font-weight: $extra-light-font;
    font-size: 32px;
    line-height: 42px;
    @include media("≥tablet") {
      font-size: 40px;
      line-height: 53px;
    }
  }
}

.leadership-list {
  list-style: none;
  padding: 13px 0 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 31px 28px;

  @include media("≥phone") {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media("≥tablet") {
    grid-template-columns: repeat(3, 1fr);
    gap: 47px 28px;
  }
}

.leadership-item {
  position: relative;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: calc(21 / 14);

  @include media("≥tablet") {
    font-size: 18px;
    line-height: calc(28 / 18);
  }

  &:hover {
    .leadership-item__visual {
      &::before,
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__visual {
    position: relative;
    padding-top: 117.2%;
    margin-bottom: 23px;
    background-color: #d9d9d9;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($gray-400, 0.75);
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: all $animation-speed;
    }

    &::after {
      position: absolute;
      content: "+";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 136px;
      height: 136px;
      background: $white;
      box-shadow: 0px 0px 40px #979898;
      border-radius: 50%;
      font-weight: 100;
      font-size: 110px;
      line-height: 120px;
      text-align: center;
      color: #597eff;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      transition: all $animation-speed;
    }

    @include media("≥tablet") {
      margin-bottom: 32px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  h4 {
    font-weight: $semibold;
    letter-spacing: 0.18em;
    text-transform: uppercase;
    color: $light-brown;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;

    @include media("≥tablet") {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

a.lightbox.leadership-item {
  color: inherit;
}

.fancybox-slide.fancybox-slide--html.fancybox-slide--current.fancybox-slide--complete {
  padding: 0;
}

.leadership-item-fancy-wrapper {
  padding: 0;
  width: 100%;
  min-height: 100vh;

  @include media("≥tablet") {
    width: 71.6%;
    min-height: auto;
  }
}

.leadership-item-fancy {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 20px;
  color: $darker-brown;
  min-height: 100vh;

  @include media("≥tablet") {
    flex-direction: row;
    font-size: 13px;
    line-height: 18px;
    min-height: auto;
  }

  @include media("≥desktop") {
    font-size: 16px;
    line-height: 26px;
  }

  h4 {
    color: $light-brown;
    font-weight: $semibold;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.18em;
    text-transform: uppercase;
    margin-bottom: 0;

    @include media("≥tablet") {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 10px;
    }
  }

  &__visual {
    position: relative;
    width: 100%;
    padding-top: 109%;
    background: #d9d9d9;

    @include media("≥tablet") {
      width: 50%;
      padding-top: 65%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15% 10% 10%;
    flex-grow: 1;

    @include media("≥tablet") {
      width: 50%;
      padding: 10% 6% 6%;
    }
  }

  .icon-linkedin-in {
    &::before {
      color: #706257;
      font-size: 25px;

      @include media("≥tablet") {
        font-size: 34px;
      }

      &:hover {
        color: $black;
      }
    }
  }
}

.text-hold {
  flex-grow: 1;
}

.leader-role {
  display: inline-block;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;

  @include media("≥tablet") {
    font-size: 18px;
    margin-bottom: 30px;
  }

  @include media("≥desktop") {
    margin-bottom: 50px;
  }
}

.leader-role {
  span:hover {
    color: #989591;
  }
}

.soc-list {
  list-style: none;
  padding: 0;
  margin: 0 -10px;

  li {
    padding: 0 10px;
  }
}

.fancybox-active {
  overflow: hidden;
}

.fancybox-content {
  animation: fancy-slide 1s;
}

@keyframes fancy-slide {
  0% {
    transform: translateY(-100vh);
  }

  100% {
    transform: translateY(0);
  }
}

.fa-cont {
  display: none;
}

.fa-cont .fancybox-content {
  display: block;
}

.fancybox-button.fancybox-close-small {
  color: #d9d9d9;
  width: 60px;
  height: 60px;
  background: $white;

  @include media("≥tablet") {
    right: 28px;
    top: 28px;
    background: transparent;
  }

  &:hover {
    color: #989591;
  }
}
