.education-pillars-section {
  padding: 0 0 140px;
  
  @include media('≥tablet') {
    padding: 15px 0 224px;
  }

  .heding-holder {
    max-width: 640px;
  }
}

.education-pillars-slider {
  padding: 10px 0 0;
  margin-right: -40px;

  @include media("≥1390px") {
    margin-right: calc((100vw - 1310px) / 2 * -1);
  }

  .slick-list {
    padding-right: 40px;

    @include media("≥1390px") {
      padding-right: 80px;
    }
  }
}

.education-pillars-card {
  position: relative;
  display: block;
  padding-top: 89.4%;
  color: $white;
  font-weight: $light-font;
  font-size: 4vw;
  line-height: calc(22 / 16);
  background-color: #f4f4f4;
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 22px 0 0;
  overflow: hidden;

  @include media('≥phone') {
    font-size: 2.2vw;
  }
  
  @include media('≥tablet') {
    margin: 0 30px 0 0;
    font-size: 1.4vw;
  }
  
  @include media('≥1512px') {
    font-size: 22px;
    line-height: 30px;
  }

  .education-card-icon {
    font-size: 30px;

    @include media('≥phone') {
      font-size: 20px;
    }

    @include media('≥tablet') {
      font-size: 24px;
    }
    
    @include media('≥widescreen') {
      font-size: 40px;
    }
  }

  p {
    margin: 0;
  }

  &::before {
    position: absolute;
    content: '';
    transition: all .8s linear;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    width: 200%;
    height: 200%;
    z-index: 2;
    box-shadow: 0 -400px 230px 20px rgba(177, 155, 138, 98%);
  }
  
  &:hover {
    color: $white;
  }
    
  &--green:hover {
    &::before {
      box-shadow: 0 -400px 230px 20px rgba(94, 194, 165, 98%);
    }
  }

  &--chartreuse:hover {
    &::before {
      box-shadow: 0 -400px 230px 20px rgba(180, 189, 0, 98%);
    }
  }

  &--orange:hover {
    &::before {
      box-shadow: 0 -400px 230px 20px rgba(245, 128, 37, 98%);
    }
  }

  &--cornflower:hover {
    &::before {
      box-shadow: 0 -400px 230px 20px rgba(89, 126, 255, 98%);
    }
  }

  &--blue:hover {
    &::before {
      box-shadow: 0 -400px 230px 20px rgba(82, 189, 236, 98%);
    }
  }

  &:focus {
    color: $white;
  }

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15%;
    z-index: 3;
  }
}
