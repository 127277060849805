// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site
// -----------------------------------------------------------------------------
[id="footer"] {
    background: $base-background-footer;

    h2 {
        font-size: 40px;
        line-height: calc(53 / 40);
        margin-bottom: 6px;
    }

    .btn {
        margin-top: 10px;
        @include media('≥tablet') {
            margin-top: -15px;
        }
    }

    hr {
        border: 1px solid rgba(209, 195, 181, 0.75);
    }

    a {
        h6 {
            transition: all $animation-speed;
            display: inline-block;
            &:hover {
                color: $black;
            }
        }
    }
}

.footer-top  {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 42px 0 39px;
    font-size: 18px;
    line-height: calc(28 / 18);
    letter-spacing: -0.035em;
    color: $darker-brown;
    
    @include media('≥tablet') {
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        padding: 42px 0 63px;
    }

    .btn-wrap {
        flex-shrink: 0;
        margin-left: 20px;
    }
}

.footer-body {
    display: none;
    @include media('≥tablet') {
        display: block;
    }
}

.brands-list {
    list-style: none;
    padding: 0;
    margin: 0 0 72px;
    font-weight: $normal;
    font-size: 14px;
    line-height: calc(24 / 14);
    letter-spacing: -0.035em;

    @include media('≥tablet') {
        display: grid;
		grid-template-columns: repeat(4, 1fr);
        gap: 15px;
    }
    @include media('≥desktop') {
        gap: 30px;
    }

    p {
        margin: 0;
    }
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 32px 0;
    font-weight: $normal;
    font-size: 12px;
    line-height: calc(28 / 12);
    letter-spacing: -0.035em;
    color: $darker-brown;
    
    @include media('≥tablet') {
        flex-direction: row;
        text-align-last: left;
        padding: 40px 0;
    }

    .icon-linkedin-in {
        padding: 3px;
        font-size: 17px;

        &::before {
            color: $darker-brown;
            transition: all $animation-speed;
        }
        
        &:hover {
            &::before {
                color: $black;
            }
        }
    }
    
    p {
        margin: 0 5px 0;
    }
}

.footer-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    @include media('≥tablet') {
        flex-direction: row;
        margin-bottom: 0;
    }
}

.footer-logo {
    width: 48px;
    min-width: 48px;
    margin-bottom: 33px;
    @include media('≥tablet') {
        margin-bottom: 0;
        margin-right: 35px;
    }
}

.copyright {
    a {
        text-decoration: underline;
    }
}