.text-section {
	padding: 56px 0 70px;
	font-size: 18px;
	line-height: calc(28 / 18);
	letter-spacing: -0.035em;
	text-align: center;
	
	@include media('≥tablet') {
		padding: 110px 0 0;
		font-size: 24px;
		line-height: calc(36 / 24);
	}

	.container {
		max-width: 830px;
	}

	h1 {
		line-height: calc(44 / 36);
		margin-bottom: 21px;

		@include media('≥tablet') {
			font-weight: $thin;
			font-size: 96px;
			line-height: 96px;
		}
	}
}
