.contact-us-section {
  padding: 40px 0;
  height: 100vh;

  @include media("≥phone") {
    padding: 77px 40px 0;
    max-height: 750px;
  }

  .container {
    width: 100vw;

    @include media("≥phone") {
      width: 460px;
    }
  }

  h2 {
    font-size: 36px;
    line-height: calc(44 / 36);
    margin-bottom: 17px;

    @include media("≥tablet") {
      font-size: 40px;
      line-height: calc(53 / 40);
      margin-bottom: 37px;
    }
  }
}

.contacy-logo {
  margin-bottom: 40px;
  @include media("≥phone") {
    display: none;
  }
}

.form-wrap {
  max-width: 100%;

  form {
    display: flex;
    flex-direction: column;
  }

  input[type="text"]:not(textarea),
  input[type="tel"]:not(textarea),
  input[type="email"]:not(textarea),
  input[type="search"]:not(textarea),
  input[type="password"]:not(textarea),
  input[type="url"]:not(textarea),
  textarea:not(textarea) {
    height: 46px;
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="search"],
  input[type="password"],
  input[type="url"],
  textarea {
    background: #fafafa;
    border: none;
    width: 100%;

    &::placeholder {
      color: $darker-brown;
      @include media("≥tablet") {
        font-weight: $normal;
        font-size: 16px;
        line-height: calc(26 / 16);
      }
    }
  }

  .textarea {
    height: 300px;

    @include media("≥tablet") {
      margin-bottom: 22px;
    }
  }
}

.row-form {
  padding: 17px 0;

  @include media("≥desktop") {
    padding: 12px 0;
  }
}
